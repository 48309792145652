import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Basic extends Component {

  render() {
  	const { title, content } = this.props
    return (
      <section className='services-block approach'>
        <div className='services-block__inner'>
          <Fade bottom distance={'40px'} delay={300}>
            <h1>{title}</h1>
            { content &&
              <div className='services-block__content approach__content' dangerouslySetInnerHTML={{ __html: content }} />
            }
          </Fade>
        </div>
      </section>
    )
  }
}

export default Basic