import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Home extends Component {

  render() {
  	const { gallery } = this.props
    const rand = gallery[Math.floor(Math.random() * gallery.length)];
    return (
      <section className='home-block'>
        <div className='home-block__inner'>
          <img className='desktop' src={rand.image.localFile.childImageSharp.original.src  + '?rand=' + Math.random()} alt={rand.image.alt_text} />
          <img className='mobile' src={rand.mobile_image.localFile.childImageSharp.original.src  +'?rand=' + Math.random()} alt={rand.mobile_image.alt_text} />
          <Fade bottom distance={'40px'} delay={300}>
            <h1>{rand.tagline}</h1>
          </Fade>
        </div>
      </section>
    )
  }
}

export default Home
