import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

//import { Plus, Minus } from './icons'

/*
<Fade bottom distance={'40px'} delay={300}>
  { accordion &&
    <div className='accordion'>
      { accordion.map((el, i) => (
        <div className={`accordion__item ${itemActive[i] ? 'active' : ''}`} key={i}>
          <h4 className='accordion__title' onClick={e => this._toggleItem(i)}>
            <span className='mobile-arrow'>
              <span className='minus'><Minus colour={'#000'} /></span>
              <span className='plus'><Plus colour={'#000'} /></span>
            </span>
            <span className='title'>{el.title}</span>
            <span className="arrow">
              <span className='minus'>–</span>
              <span className='plus'>+</span>
            </span>
          </h4>
          <div className='accordion__content' ref={`q_${i}`}>
            <div dangerouslySetInnerHTML={{ __html: el.content }} />
          </div>
        </div>
      ))}
    </div>
  }
</Fade>
*/

class Approach extends Component {

  // state = {
  //   itemActive: {}
  // }

  // collapseSection(element) {
  //   let sectionHeight = element.scrollHeight

  //   let elementTransition = element.style.transition
  //   element.style.transition = ''

  //   requestAnimationFrame(function() {
  //     element.style.height = sectionHeight + 'px'
  //     element.style.transition = elementTransition

  //     requestAnimationFrame(function() {
  //       element.style.height = 0 + 'px'
  //     })
  //   })

  //   element.setAttribute('data-collapsed', 'true')
  // }

  // expandSection(element) {
  //   let sectionHeight = element.scrollHeight
  //   element.style.height = sectionHeight + 'px'
  //   element.setAttribute('data-collapsed', 'false')
  // }

  // _toggleItem = (itemId) => {
  //   let current = this.state.itemActive[itemId]
  //   let { itemActive } = this.state
  //   itemActive[itemId] = !current
  //   let element = this.refs[`q_${itemId}`]
  //   current ? this.collapseSection(element) : this.expandSection(element)
  //   this.setState({ itemActive })
  // }

  render() {
  	const { title, content } = this.props
    //let { itemActive } = this.state
    return (
      <section className='services-block approach'>
        <div className='services-block__inner'>
          <Fade bottom distance={'40px'} delay={300}>
            <h1>{title}</h1>
            { content &&
              <div className='services-block__content approach__content' dangerouslySetInnerHTML={{ __html: content }} />
            }
          </Fade>
        </div>
      </section>
    )
  }
}

export default Approach
