import React from 'react'

import Home from '../components/home'
import Services from '../components/services'
import Approach from '../components/approach'
import Basic from '../components/basic'
import People from '../components/people'
import Clients from '../components/clients'
import Contact from '../components/contact'
import Image from '../components/image'
import Content from '../components/content'
import Blockquote from '../components/blockquote'
import Video from '../components/video'

const renderBlock = (param, el, i, page={}) => {
  let block = {
    'WordPressAcf_home' : (el, i) => <Home key={i} {...el} />,
    'WordPressAcf_ouroffering' : (el, i) => <Services key={i} {...el} />,
    'WordPressAcf_basic' : (el, i) => <Basic key={i} {...el} />,
    'WordPressAcf_approach' : (el, i) => <Approach key={i} {...el} />,
    'WordPressAcf_people' : (el, i) => <People key={i} {...el} />,
    'WordPressAcf_clients' : (el, i) => <Clients key={i} {...el} />,
    'WordPressAcf_contact' : (el, i) => <Contact key={i} {...el} />,
    'WordPressAcf_image' : (el, i) => <Image key={i} {...el} />,
    'WordPressAcf_content' : (el, i) => <Content key={i} {...el} />,
    'WordPressAcf_blockquote' : (el, i) => <Blockquote key={i} {...el} />,
    'WordPressAcf_video' : (el, i) => <Video key={i} {...el} />,
  }[param]

  if (!block) return


  return block(el, i)
}

export default renderBlock