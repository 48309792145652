import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import { Plus, Minus } from './icons'

class People extends Component {

  state = {
    itemActive: {}
  }

  collapseSection(element) {
    let sectionHeight = element.scrollHeight

    let elementTransition = element.style.transition
    element.style.transition = ''

    requestAnimationFrame(function() {
      element.style.height = sectionHeight + 'px'
      element.style.transition = elementTransition

      requestAnimationFrame(function() {
        element.style.height = 0 + 'px'
      })
    })

    element.setAttribute('data-collapsed', 'true')
  }

  expandSection(element) {
    let sectionHeight = element.scrollHeight
    element.style.height = sectionHeight + 'px'
    element.setAttribute('data-collapsed', 'false')
  }

  _toggleItem = (itemId) => {
    let current = this.state.itemActive[itemId]
    let { itemActive } = this.state
    itemActive[itemId] = !current
    let element = this.refs[`q_${itemId}`]
    current ? this.collapseSection(element) : this.expandSection(element)
    this.setState({ itemActive })
  }

  render() {
  	const { title, content, people } = this.props
    let { itemActive } = this.state
    return (
      <section className='services-block people'>
        <div className='services-block__inner'>
          <Fade bottom distance={'40px'} delay={300}>
            <h1>{title}</h1>
            { content &&
              <div className='services-block__content' dangerouslySetInnerHTML={{ __html: content }} />
            }
          </Fade>
          <Fade bottom distance={'40px'} delay={300}>
            { people &&
              <div className='accordion'>
                { people.map((el, i) => (
                  <div className={`accordion__item ${itemActive[i] ? 'active' : ''}`} key={i}>
                    { el.profile_picture && <img src={el.profile_picture.localFile.childImageSharp.original.src} alt={el.profile_picture.alt_text} onClick={e => this._toggleItem(i)} /> }
                    <h4 className={ el.profile_picture ? 'accordion__title' : 'accordion__title border'} onClick={e => this._toggleItem(i)}>
                      <span className='mobile-arrow'>
                        <span className='minus'><Minus colour={'#000'} /></span>
                        <span className='plus'><Plus colour={'#000'} /></span>
                      </span>
                      <span>
                        <span className='name'>{el.name}</span>
                        <span className='position'>{el.position}</span>
                      </span>
                    </h4>
                    <div className='accordion__content' ref={`q_${i}`}>
                      <div dangerouslySetInnerHTML={{ __html: el.bio }} />
                      { el.linkedin && <a href={el.linkedin} className='btn' target='_blank' rel='noopener noreferrer'>LinkedIn</a> }
                    </div>
                  </div>
                ))}
              </div>
            }
          </Fade>
        </div>
      </section>
    )
  }
}

export default People
