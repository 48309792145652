import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Blockquote extends Component {
  render() {
  	const { blockquote } = this.props
    return (
      <section className='blockquote-block'>
      	<Fade bottom distance={'40px'}>
        	<div className='blockquote-block__wrapper' dangerouslySetInnerHTML={{ __html: blockquote }} />
       	</Fade>
      </section>
    )
  }
}

export default Blockquote
