import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Content extends Component {
  render() {
  	const { content } = this.props
    return (
      <section className='content-block'>
      	<Fade bottom distance={'40px'}>
	        <div dangerouslySetInnerHTML={{ __html: content }} />
        </Fade>
      </section>
    )
  }
}

export default Content
