import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Video extends Component {
  render() {
  	const { video } = this.props
    return (
      <section className='image-block'>
      	<Fade bottom distance={'40px'}>
        	<div className='video-wrapper' dangerouslySetInnerHTML={{ __html: video }} />
        </Fade>
      </section>
    )
  }
}

export default Video
