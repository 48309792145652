import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Image extends Component {

  getImageSrc(obj) {
    let src = obj && obj.localFile && obj.localFile.childImageSharp && obj.localFile.childImageSharp.original.src
    if (obj && obj.source_url.indexOf('.gif') !== -1) {
      src = obj.source_url
    }
    return src
  }

  render() {
  	const { image, caption } = this.props
    return (
      <section className='image-block'>
      	<Fade bottom distance={'40px'}>
          <>
          	<img src={this.getImageSrc(image)} alt={image && image.alt_text} />
            { caption && <span>{caption}</span> }
          </>
        </Fade>
      </section>
    )
  }
}

export default Image
