import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Clients extends Component {

  render() {
  	const { title, clients } = this.props
    return (
      <section className='clients-block'>
        <div className='clients-block__inner'>
          <Fade bottom distance={'40px'} delay={300}>
            <h1>{title}</h1>
            { clients &&
              <div className='clients-block__items'>
                { clients.map((el, i) => (
                  <a href={el.link} target='_blank' rel='noopener noreferrer' key={i}>
                    <img src={el.logo.localFile.childImageSharp.original.src} alt={el.logo.alt_text} />
                  </a>
                ))}
              </div>
            }
          </Fade>
        </div>
      </section>
    )
  }
}

export default Clients
